import React from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";

const CompanyPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Company
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8">
          <div className="max-w-6xl mx-auto">
            <p>
              Coolcar is a car rental company located in Naoussa, Paros. We provide rental & transfer services, and we
              are dedicated to delivering the best possible customer experience to everyone we serve.</p>
            <p className="mt-4">
              At Coolcar, we believe that our customers are our most valuable asset. That's why we go
              above and beyond to ensure that they have a positive and memorable experience with us. From our friendly
              and knowledgeable staff to our reliable and well-maintained vehicles, we are committed to providing the
              highest level of customer service possible.</p>
            <p className="mt-4">
              In addition, Coolcar is open to collaborations with hotels and other car rental companies. We understand
              the importance of building strong partnerships with other businesses in our industry, and we are always
              looking for ways to work together to better serve our customers.
            </p>
            <p className="mt-4">
              Thank you for considering Coolcar for your car rental needs. We look forward to serving you and helping
              you make the most of your time on Paros.</p>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Company | Coolcar Rentals | Reliable and Affordable Car Rentals"
    description="Discover Coolcar, a Naousa-based car rental company that offers transfer services and exceptional customer experience."
  />
)

export default CompanyPage;